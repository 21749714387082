import React, { useEffect, useState } from 'react';
import { iMessageWindow } from '../../interface.td';

function MessageScreen({ message, title, redirect, hideWindow, canClose } : iMessageWindow) {
  const [close, setClose] = useState(true);

  useEffect(()=>{
    if(canClose) setClose(canClose);
  },[]);

  return (
    <div id='messages' className='flex justify-center top-0 left-0 fixed w-full h-full bg-[#00000085]'>
      <div className='flex w-1/2 justify-center items-center'>
        <div className='flex flex-col bg-white h-fit p-8'>
          <h1 className='text-4xl mb-8 self-center place-self-center'>{title}</h1>
          <p className='text-sm m-2'>{message}</p>
          {(close) ? <button onClick={(e)=> {
            if(redirect){
              document.location = redirect;
            }
            hideWindow(false)
          }} className='mt-4 p-2 bg-green-200'>Fechar</button> : <></>}
        </div>
      </div>
    </div>
  );  
}

export default MessageScreen;