import { useEffect, useState } from "react";
import { iAdminList } from "../../interface.td";
import { AdminDashboardClass } from "../../classes/admin";
import Cookies from "universal-cookie";

function UsersWindow(){
    const cookies = new Cookies();
    const [adminList, setAdminList] = useState<iAdminList[]>([]);

    useEffect(()=>{
        async function fetchAdminList() {
            const data = await (new AdminDashboardClass()).getManagerList(cookies.get("Authentication"),"manager");
            setAdminList(data);
        }
        fetchAdminList();
    },[]);

    return(
        <table className="flex flex-col justify-between text-sm">
            <tr className="flex justify-between m-1">
                <th>#</th>
                <th>Nome</th>
                <th>Contacto</th>
                <th>Organização</th>
                <th>Email</th>
            </tr>
            <tr>
                {adminList.map((item,key)=> <li key={key} className="flex justify-between m-2">
                    <th>{key + 1}</th>
                    <th>{item.name}</th>
                    <th>{item.email}</th>
                    <th>{item.role}</th>
                    <th>{item.email}</th>
                </li> )}
            </tr>
        </table>
    )
}

export default UsersWindow;