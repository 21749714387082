export function setColor(text : string) : string{
    if(text === "approved") return "bg-green-300";
    else if(text === "pending") return "bg-yellow-300";
    else return "bg-red-300";
}

export function setText(text : string) : string{
    if(text === "approved") return "Submetido";
    else if(text === "pending") return "Em análise";
    else return "Rejeitado";
}