import { iMenuProps } from "../../interface.td";
import { useEffect, useState } from "react";
import Cookies from 'universal-cookie';
import { parseJwt } from "../../functions/jwt";

function AdminMenu(props : iMenuProps){
    const [role, setRole] = useState("");
    const cookies = new Cookies();

    useEffect(()=>{
        const token = parseJwt(cookies.get("Authentication"));
        if(token){
            if(token["role"] === "admin") setRole("admin");
        }

    },[]);

    return (
        <ul>
            {(role !== "admin") ?
                <li onClick={(e)=> props.setMenuRouter(0)} className='p-2 mb-2 bg-slate-200 hover:bg-slate-700 hover:text-white'>Candidaturas</li> :
                <></>
            }
            {(role === "admin") ?
                <li onClick={(e)=> props.setMenuRouter(1)} className='p-2 mb-2 bg-slate-200 hover:bg-slate-700 hover:text-white'>Definições</li> :
                <></>
            }
            <li onClick={(e)=> props.setMenuRouter(2)} className='p-2 mb-2 bg-slate-200 hover:bg-slate-700 hover:text-white'>Utilizadores</li>
            {(role !== "admin") ?
            <li onClick={async(e)=>{
                const file = await fetch(process.env.REACT_APP_API_ROUTE + "api/admin/submission/logs/export",{
                    headers : {
                        "Authentication" : cookies.get("Authentication")
                    }
                });
                const blob = await file.blob();
                const download = window.URL.createObjectURL(blob);
                window.location.assign(download);

            }} className='p-2 mb-2 bg-slate-200 hover:bg-slate-700 hover:text-white'>Registro de Aprovação</li> :
            <></>
            }
            {(role !== "admin") ?
            <li onClick={async(e)=>{
                const file = await fetch(process.env.REACT_APP_API_ROUTE + "api/admin/submission/export",{
                    headers : {
                        "Authentication" : cookies.get("Authentication")
                    }
                });
                const blob = await file.blob();
                const download = window.URL.createObjectURL(blob);
                window.location.assign(download);

            }} className='p-2 mb-2 bg-slate-300 hover:bg-slate-700 hover:text-white'>Exportar</li> :
            <></>
            }
            <li onClick={(e)=>{
                cookies.remove("Authentication",{ path : "/" });
                document.location = "/admin";
            }} className='p-2 mb-2 bg-slate-200 hover:bg-slate-700 hover:text-white'>Sair</li>
        </ul>
    )
}

export default AdminMenu;