import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Signup from './pages/signup/Signup';
import Verify from './pages/signup/verify/Verify';
import UserDashboard from './pages/signup/app/UserDashboard';
import AdminDashboard from './pages/signup/admin/Admin';
import AdminLogin from './pages/signup/admin/Login';
import UserSubmission from './pages/signup/app/Submission';
import Recover from './pages/signup/Recover';
import RecoverNewPassword from './pages/signup/RecoverNewPassword';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  { path: "/", element: <App/>, },
  { path: "/login", element: <App/>, },
  { path: "/signup", element: <Signup/>, },
  { path: "/recover", element: <Recover/>, },
  { path: "/recover/:token", element: <RecoverNewPassword/>, },
  { path: "/signup/verify/:token", element: <Verify/> },
  { path: "/app/dashboard", element: <UserDashboard/> },
  { path: "/app/submission", element: <UserSubmission/> },
  { path: "/admin", element: <AdminLogin/> },
  { path: "/admin/dashboard", element: <AdminDashboard/> },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();