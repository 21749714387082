function SubmissionRole(){
    return (
        <>
            <div>
                <h1 className='text-3xl mb-5'>REGULAMENTO DE ACESSO AOS IMÓVEIS DAS TORRES EUCALIPTOS</h1>
            </div>
            <div className='h-[80%] overflow-y-scroll'>
                <div className="mb-2">
                    <p className="mt-8 mb-4">
                    &emsp;Havendo a necessidade de garantir um processo transparente, justo e sistematizado da alienação de imóveis habitacionais aos funcionários do MAPTSS e órgãos por si superintendidos a Comissão de Trabalho criada ao abrigo do Ofício N.°248/GAB.MAPTSS/2023, datado de 04 de Dezembro de 2023, na sua II. a reunião de Trabalho aprova o presente Regulamento de acesso aos imóveis das
    Torres Eucaliptos que se rege pelos seguintes artigos.
                    </p>
                </div>
                <div className="mb-2">
                    <h2 className="font-bold text-xl m-4">Artigo 1° (Objecto)</h2>
                    <p>
                    &emsp;O presente regulamento estabelece os requisitos de elegibilidade, termos, condições e custos de venda na modalidade de renda resolúvel dos imóveis T3, do projecto residencial denominado Torres Eucaliptos, sito na Avenida Hoji Ya Henda, Distrito Urbano do Rangel, Luanda.
                    </p>
                </div>
                <div className="mb-2">
                    <h2 className="font-bold text-xl m-4">Artigo 2° (Incidência Subjectiva)</h2>
                    <ol>
                        <li>
                            <p> &emsp;O acesso aos imóveis disponíveis na modalidade de renda resolúvel do empreendimento Torres Eucaliptos restringe-se aos Funcionários Públicos e aos Agentes públicos em Comissão de serviço vinculados materialmente ao MAPTSS e aos Órgãos por si superintendidos residente e que desenvolvam as suas actividades na Província de Luanda.</p>
                        </li>
                        <li>
                            <p> &emsp;Não têm direito de aceder aos imóveis das Torres Eucaliptos os funcionários públicos ou agentes públicos referidos no número anterior que:</p>
                            <ol className="ml-8 list-disc list-inside">
                                <li>Sejam titulares de residências no regime de compra liquidatária ou a renda resolúvel no âmbito da política habitacional do sector ou da política habitacional do Estado por intermédio do MAPTSS;</li>
                                <li>Aufiram um rendimento mensal no sector inferior a Kz.680 100,00 (Seiscentos e oitenta mil e cem kwanzas);</li>
                                <li>Que nos últimos 24 meses tenham sido alvos de processos disciplinares.</li>
                            </ol>
                        </li>
                        <li>
                            <p>A enumeração do número anterior não é cumulativa, bastando-se com a verificação de um dos factos previstos numa das alíneas.</p>
                        </li>
                    </ol>
                </div>
                <div className="mb-2">
                    <h2 className="font-bold text-xl m-4">Artigo 3.° (Valor do Imóvel)</h2>
                    <p> &emsp;O valor do imóvel, determinado por estudos de viabilidade, é de Kz. 77 982 053 (Setenta e Sete Milhões, Novecentos e Oitenta e Dois Mil e Cinquenta e Três Kwanzas).
    Artigo 4.° (Atribuição de Quotas)</p>
                </div>
                <div className="mb-2">
                    <h2 className="font-bold text-xl m-4">Artigo 4.° (Atribuição de Quotas)</h2>
                    <ol>
                        <li>
                            <p> &emsp;Para melhor equidade na distribuição dos 42 imóveis são atribuídas as seguintes quotas:</p>
                            <ul className="ml-8 list-disc list-inside">
                                <li>apartamentos para o Ministério da Administração Pública
                                Trabalho e Segurança Social;</li>
                                <li>apartamentos para o INSS;</li>
                                <li>apartamentos para o INEFOP;</li>
                                <li>apartamentos para o INQ;</li>
                                <li>apartamentos para o SIAC;</li>
                                <li>apartamentos para a IGT;</li>
                                <li>apartamentos para a ENAPP-EP.</li>
                            </ul>
                        </li>
                        <li>
                            <p> &emsp;Se as unidades orgânicas referidas no número anterior não preencherem as quotas, por deliberação, a Comissão de Trabalho atribuirá a um outro órgão, que deverá remeter um outro concorrente no prazo mínimo de 24 horas.</p>
                        </li>
                    </ol>
                </div>
                <div className="mb-2">
                    <h2 className="font-bold text-xl m-4">Artigo 5.° (Critérios de Preferência)</h2>
                    <ol>
                        <li>
                            <p> &emsp;São critérios de preferência por:</p>
                            <ul className="ml-8 list-disc list-inside">
                                <li>Capacidade Económica;</li>
                                <li>Relevância da função e o perfil do candidato;</li>
                                <li>Hierarquia;</li>
                                <li>Avaliação de Desempenho;</li>
                                <li>Antiguidade;</li>
                            </ul>
                        </li>
                        <li>
                            <p> &emsp;Tendo em conta a necessidade de manutenção e recuperação do investimento e face as lições aprendidas em projectos similares, o principal critério de preferência será o da capacidade financeira dos funcionários considerando o rendimento auferido no sector.</p>
                        </li>
                        <li>
                            <p> &emsp;Caso haja igualdade de requisitos, deve ser adoptado o critério do nível de relevância da função e o perfil do candidato, tendo em conta o interesse estratégico para os processos e programas críticos do sector.</p>
                        </li>
                    </ol>
                </div>
                <div className="mb-2">
                    <h2 className="font-bold text-xl m-4">Artigo 6. ° (Processo das Candidaturas)</h2>
                    <ol>
                        <li>
                            <p> &emsp;O processo das candidaturas compreende as seguintes fases:</p>
                            <ul className="ml-8 list-disc list-inside">
                                <li>Divulgação aos Órgãos do Sector sobre a distribuição das quotas de apartamentos a si atribuídas;</li>
                                <li>Envio do Regulamento de acesso aos interessados pelo
Presidente da Comissão do Trabalho aos responsáveis dos
Órgãos do Sector, para abertura oficial das candidaturas;</li>
                                <li>Recolha das candidaturas por via portal electrónico, no prazo de 5 dias de calendário, a contar da data da comunicação oficial da abertura das candidaturas;</li>
                                <li>Publicação interna das candidaturas válidas de acordo às regras estabelecidas no presente regulamento por parte dos titulares dos Órgãos do Sector, no prazo de 5 dias, findo o prazo previsto no número anterior;</li>
                                <li>Apuramento da lista dos beneficiários melhores cotados, considerando as quotas estabelecidas no número 1 do artigo
4.° do presente Regulamento e submissão, para efeitos de aprovação à Comissão de Trabalho, no prazo de 5 dias úteis, após à publicação prevista na alínea anterior, por parte dos titulares dos Órgãos do Sector.</li>
                                <li>Remessa da lista dos beneficiários à Superintendência, para efeitos de conhecimento, após à aprovação prescrita na alínea anterior, por parte da Comissão de Trabalho.</li>
                                <li>Celebração dos contratos promessa de compra e venda entre o INSS e os melhores cotados, no prazo de 15 dias, a contar da data da remessa prevista na alínea anterior.</li>
                            </ul>
                        </li>
                        <li>
                        &emsp;Sob pena de invalidade da candidatura, devem os candidatos elaborar um requerimento a manifestar a renúncia de transmissão temporária ou definitiva dos direitos sobre o imóvel, cedência de uso ou alienação, por um periodo não inferior a 5 anos, a contar da data da assinatura do contrato promessa de compra e venda, caso sejam apurados como melhores cotados, nos termos da alínea e) do número 2 do presente.
                        </li>
                        <li>
                        &emsp;Sob pena de invalidade das candidaturas, devem os candidatos elaborar uma declaração de aceitação incondicional da minuta de contrato anexa ao presente.
                        </li>
                    </ol>
                </div>
                <div className="mb-2">
                    <h2 className="font-bold text-xl m-4">Artigo 7. ° (Casos Omissos)</h2>
                    <p> &emsp;As dúvidas suscitadas na interpretação e aplicação das disposições contidas no presente Regulamento serão esclarecidas pela
Comissão de Trabalho.</p>
                </div>
                <div className="mb-2">
                    <h2 className="font-bold text-xl m-4">Artigo 8. ° (Entrada em Vigor)</h2>
                    <p> &emsp;O presente Regulamento entra na data da assinatura dos membros da Comissão de Trabalho.</p>
                </div>
                <div className="mt-6 text-center text-sm">
                    Comissão de Trabalho, em Luanda aos 10 de Abril de 2024.
                </div>
            </div>
        </>
    )
}

export default SubmissionRole;