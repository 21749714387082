import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import { Authentication } from '../classes/authentication';
import { AppFooter, AppHeader } from '../components/AppHeading';
import { background } from '../assets';

function App() {
  // 
  const [loginState, setLoginState] = useState("solved" || "pending");
  const cookies = new Cookies();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  // yeah... duplicated lines uh

  async function Login() {
    setLoginState("pending");
    const authentication = await (new Authentication()).Login(email, password);
    if (authentication["code"] === "LOGIN_SUCCESS") {
      cookies.set('Authentication', authentication["token"]);
      const check = await (new Authentication()).CheckVerified(authentication["token"]);
      if (check["code"] === "ACCOUNT_STATUS_NOT_VERIFIED") document.location = "/app/submission";
      else if(check["code"] === "ACCOUNT_STATUS_VERIFIED") document.location = "/app/dashboard";
    } else {
      setMessage(authentication["message"])
      setTimeout(() => {
        setMessage('');
      }, 5000);
    }
    setLoginState("solved");
  }

  useEffect(()=>{
    async function checkSession(){
      if(cookies.get("Authentication")){
        const check = await (new Authentication()).CheckVerified(cookies.get("Authentication"));
        if (check["code"] === "ACCOUNT_STATUS_NOT_VERIFIED") document.location = "/app/submission";
        else if(check["code"] === "ACCOUNT_STATUS_VERIFIED") document.location = "/app/dashboard";
      }
    }
    checkSession();
  },[])

  return (
    <body className='flex flex-col h-full'>
      <AppHeader link='/'/>
      <main className='h-full flex items-center justify-start' style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition : "top"
      }}>
        <div className='flex md:w-[30%] flex-col p-6 m-4 ml-16 bg-white'>
          <h1 className='text-4xl pb-10 self-center place-self-center'>Acessar Conta</h1>
          <p className='text-sm pb-2 self-center place-self-center'>{message}</p>
          <input onChange={(e) => setEmail(e.target.value)} className='p-2 border-2 mb-2 text-sm' type="email" placeholder='Email' />
          <input onChange={(e) => setPassword(e.target.value)} className='p-2 border-2 text-sm' type="password" placeholder='**********' />
          <Link to={'/recover'} className='text-sm m-2 text-blue-500 hover:cursor-pointer'>
            <p>Recuperar Senha</p>
          </Link>
          <div className='w-full flex flex-col mt-6'>
            <button 
              onClick={(e) => (loginState === "solved") ? Login() : 0} 
              className={`${loginState === "pending" ? "animate-pulse" : "hover:bg-blue-200"} text-sm bg-slate-200 mt-0 p-2 flex justify-center content-center`}>
                Iniciar Sessão
            </button>
            <Link to={'/signup'} className='text-sm bg-slate-200 mt-2 p-2 flex justify-center content-center'>
              <button >Criar Conta</button>
            </Link>
          </div>
        </div>
        <div></div>
      </main>
      <AppFooter/>
    </body>
  );
}

export default App;