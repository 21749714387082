import { useEffect, useState } from "react";
import SettingsNewOrganization from "./components/swc_newOrganization";
import { iOrganization } from "../../../interface.td";
import { App } from "../../../classes/app";

function SwcOrganization(){
    const [showModal, setShowModal] = useState(false);
    const [organization, setOrganization] = useState<iOrganization[]>([]);

    useEffect(()=>{
        async function requestData(){
            const organizations = await (new App()).getInstitutes();
            if(organizations) setOrganization(organizations);
        }
        requestData();
    },[])

    return (
        <div>
            {(showModal ? <SettingsNewOrganization hideWindow={setShowModal}/> : <></>)}
            <div>
                <div>
                    <h1 className="m-2 font-bold text-lg pb-4">Organizações</h1>
                </div>
                <div>
                    <ul>
                        {organization.map((item,key)=> <li className="flex justify-between m-2">
                            <div className="flex">
                                <p>{key + 1}</p>
                                <p className="ml-16">{item.name}</p>
                            </div>
                            <div className="flex">
                                <button className="bg-blue-300 pl-2 pr-2 p-1">Editar</button>
                                <button className="bg-red-300 pl-2 pr-2 p-1">Apagar</button>
                            </div>
                        </li> )}
                    </ul>
                </div>
                <button onClick={(e)=> setShowModal(true)} className="mt-8 p-2 m-2 bg-blue-300">Adicionar</button>
            </div>
        </div>
    );
}

export default SwcOrganization;