import React, { useEffect, useState } from 'react';
import UserMenu from '../../../components/userDashboard/userMenu';
import { iSubmissionData, iSubmissionStatus } from '../../../interface.td';
import { App } from '../../../classes/app';
import Cookies from "universal-cookie";
import { AppFooter, AppHeader } from '../../../components/AppHeading';
import { background } from '../../../assets';
import { setColor, setText } from '../../../functions/colors';
import { parseJwt } from '../../../functions/jwt';

function UserDashboard() {
    // security
    const [mouseMoving, setMouseMoving] = useState(false);
    // animation
    const [loading, setLoading] = useState("solved" || "pending");
    // ======================
    const cookies = new Cookies();
    const [showStates, setShowStatus] = useState(true);
    const [candidatureData, setCandidatureData]= useState<iSubmissionData>();
    const [finalResult, setFinalResult] = useState("");
    
    const [candidatureStatus, setCandidatureStatus]= useState<iSubmissionStatus>({
        base_salary : "pending",
        bi_status : "pending",
        category_status : "pending",
        email : "pending",
        name : "pending",
        organization_status : "pending",
        role_status : "pending",
        salary_status : "pending",
        startingYear_status : "pending",
        have_debt : "pending",
        debt_value : "pending",
        debt_length : "pending",
        createdAt : new Date(),
        updatedAt : new Date()
    });

    function formatNumberKz(value : number) : String{
        return value.toLocaleString(undefined,{
            minimumFractionDigits : 2
        }) + " Kz";
    }

    useEffect(()=>{
        const time = setTimeout(()=>{
            cookies.remove("Authentication",{path:"/"});
            document.location = "/";
        },180000); // 3 minutos

        window.addEventListener("mousemove",(e)=>{
            clearInterval(time);
            setMouseMoving(true);
        })

        setMouseMoving(false);
    },[mouseMoving]);

    useEffect(()=>{
        setLoading("pending");

        // is current user an admin or a normal user?
        const parseCookie = cookies.get("Authentication");
        const isAdmin = parseJwt(parseCookie);
        if(isAdmin["role"]){
            document.location = "/admin/dashboard";
        }

        async function requestData(){
            const data = await (new App()).submissionStatus(1, parseCookie);
            const sub_data = await (new App()).submissionData(1, parseCookie);

            if(data) setCandidatureStatus(data);
            if(sub_data) setCandidatureData(sub_data);

            // yes... this is a crap code but, who cares? I'll refactor later
            if(
                "approved" == data.base_salary &&
                "approved" == data.bi_status &&
                "approved" == data.category_status && 
                "approved" == data.email &&
                "approved" == data.name &&
                "approved" == data.organization_status &&
                "approved" == data.role_status &&
                "approved" == data.salary_status && 
                "approved" == data.startingYear_status &&
                "approved" == data.have_debt &&
                "approved" == data.debt_value &&
                "approved" == data.debt_length ){
                
                setFinalResult("approved");
            }else if(
                "rejected" == data.base_salary ||
                "rejected" == data.bi_status ||
                "rejected" == data.category_status||
                "rejected" == data.email ||
                "rejected" == data.name ||
                "rejected" == data.organization_status ||
                "rejected" == data.role_status ||
                "rejected" == data.salary_status || 
                "rejected" == data.startingYear_status ||
                "rejected" == data.have_debt ||
                "rejected" == data.debt_value ||
                "rejected" == data.debt_length ){
                setFinalResult("rejected");
            }else if(
                "pending" == data.base_salary ||
                "pending" == data.bi_status ||
                "pending" == data.category_status||
                "pending" == data.email ||
                "pending" == data.name ||
                "pending" == data.organization_status ||
                "pending" == data.role_status ||
                "pending" == data.salary_status || 
                "pending" == data.startingYear_status ||
                "pending" == data.have_debt ||
                "pending" == data.debt_value ||
                "pending" == data.debt_length){
                setFinalResult("pending");
            }
            setLoading("solved");
        }
        requestData();
    },[]);

    return (
    <body className='flex flex-col h-full'>
        <AppHeader link='/'/>
        <main className='md:h-full flex items-center justify-center' style={{
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            backgroundPosition : "top"
        }}>
            <div className='flex md:w-4/5 w-full h-full flex-col p-6 md:m-4 md:ml-12 bg-white'>
                <div>
                    <h1 className='text-3xl mb-5'>Candidatura</h1>
                </div>
                <div className='flex md:flex-row flex-col h-full'>
                    <div className='md:w-[20%] md:mr-2 text-sm'>
                        <UserMenu/>
                    </div>
                    <div className='md:w-[80%] bg-slate-400 h-full'>
                        <ul className='flex justify-between content-center md:text-sm text-[0.3rm] bg-gray-200'>
                            <li className='flex justify-center items-center'>
                                <p className='md:text-sm text-[0.7rem] m-1'>Candidatura enviada em: {
                                    (candidatureData) ?
                                    String("").concat(
                                        String(new Date(candidatureStatus.createdAt).getUTCDate()),
                                        "/",
                                        String((new Date(candidatureStatus.createdAt).getMonth()) + 1),
                                        "/",
                                        String(new Date(candidatureStatus.createdAt).getFullYear())
                                    ) :
                                    <></>   
                                }</p>
                            </li>
                            {/*
                            <li>
                                <button className='bg-blue-300 w-36 p-1 m-1' onClick={(e)=> setShowStatus(showStates ? false : (!showStates ? true : false))}>Ver</button>
                            </li>*/}
                        </ul>
                        <div className={`display: ${(showStates ? 'relative' : 'hidden')}`}>
                            <ul className='md:m-2 m-3'>
                                <li className={`${loading === "pending" ? "animate-pulse" : ""} flex justify-between p-2 bg-gray-300 mb-1`}>
                                    <p className='text-sm'>Nome: {candidatureData?.userProfile.organizationDatabase.name}</p>
                                    <button className={`${setColor(candidatureStatus.name)} w-24 text-sm`}>{setText(candidatureStatus.name)}</button>
                                </li>
                                <li className={`${loading === "pending" ? "animate-pulse" : ""} flex justify-between p-2 bg-gray-300 mb-1`}>
                                    <p className='text-sm'>Instituição: {candidatureData?.userProfile.organizationDatabase.organization.name}</p>
                                    <button className={`${setColor(candidatureStatus.organization_status)} w-24 text-sm`}>{setText(candidatureStatus.organization_status)}</button>
                                </li>
                                <li className={`${loading === "pending" ? "animate-pulse" : ""} flex justify-between p-2 bg-gray-300 mb-1`}>
                                    <p className='text-sm'>Cargo: {candidatureData?.userProfile.organizationDatabase.role.name}</p>
                                    <button className={`${setColor(candidatureStatus.role_status)} w-24 text-sm`}>{setText(candidatureStatus.role_status)}</button>
                                </li>
                                <li className={`${loading === "pending" ? "animate-pulse" : ""} flex justify-between p-2 bg-gray-300 mb-1`}>
                                    <p className='text-sm'>Categoria: {candidatureData?.userProfile.organizationDatabase.category.name}</p>
                                    <button className={`${setColor(candidatureStatus.category_status)} w-24 text-sm`}>{setText(candidatureStatus.category_status)}</button>
                                </li>
                                <li className={`${loading === "pending" ? "animate-pulse" : ""} flex justify-between p-2 bg-gray-300 mb-1`}>
                                    <p className='text-sm'>Email: {candidatureData?.userProfile.organizationDatabase.email}</p>
                                    <button className={`${setColor(candidatureStatus.email)} w-24 text-sm`}>{setText(candidatureStatus.email)}</button>
                                </li>
                                <li className={`${loading === "pending" ? "animate-pulse" : ""} flex justify-between p-2 bg-gray-300 mb-1`}>
                                    <p className='text-sm'>Salário total: {formatNumberKz(Number(candidatureData?.userProfile.organizationDatabase.salary) + Number(candidatureData?.userProfile.organizationDatabase.complement))}</p>
                                    <button className={`${setColor(candidatureStatus.salary_status)} w-24 text-sm`}>{setText(candidatureStatus.salary_status)}</button>
                                </li>
                                <li className={`${loading === "pending" ? "animate-pulse" : ""} flex justify-between p-2 bg-gray-300 mb-1`}>
                                    <p className='text-sm'>Bilhete: {candidatureData?.userProfile.organizationDatabase.bi}</p>
                                    <button className={`${setColor(candidatureStatus.bi_status)} w-24 text-sm`}>{setText(candidatureStatus.bi_status)}</button>
                                </li>
                                <li className={`${loading === "pending" ? "animate-pulse" : ""} flex justify-between p-2 bg-gray-300 mb-1`}>
                                    <p className='text-sm'>Possui dívida?: {(candidatureData?.userProfile.organizationDatabase.have_debt) ? "Sim" : "Não"}</p>
                                    <button className={`${setColor(candidatureStatus.startingYear_status)} w-24 text-sm`}>{setText(candidatureStatus.startingYear_status)}</button>
                                </li>
                                <li className={`${loading === "pending" ? "animate-pulse" : ""} flex justify-between p-2 bg-gray-300 mb-1`}>
                                    <p className='text-sm'>Valor da Dívida: {candidatureData?.userProfile.organizationDatabase.debt_value}</p>
                                    <button className={`${setColor(candidatureStatus.startingYear_status)} w-24 text-sm`}>{setText(candidatureStatus.startingYear_status)}</button>
                                </li>
                                <li className={`${loading === "pending" ? "animate-pulse" : ""} flex justify-between p-2 bg-gray-300 mb-1`}>
                                    <p className='text-sm'>Meses até pagar: {candidatureData?.userProfile.organizationDatabase.debt_length} Meses</p>
                                    <button className={`${setColor(candidatureStatus.startingYear_status)} w-24 text-sm`}>{setText(candidatureStatus.startingYear_status)}</button>
                                </li>
                                <li className={`${loading === "pending" ? "animate-pulse" : ""} flex justify-between p-2 bg-gray-300 mb-1`}>
                                    <p className='text-sm'>Resultado final:</p>
                                    <button className={`${setColor(finalResult)} w-24 text-sm`}>{setText(finalResult)}</button>
                                </li>
                                <div className='m-4 w-16 h-16'></div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <AppFooter/>
    </body>
    );  
}

export default UserDashboard;