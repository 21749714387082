import { useEffect, useState } from 'react';
import { iInstitutionList } from '../../interface.td';
import { AppFooter, AppHeader } from '../../components/AppHeading';
import background from '../../assets/background.jpg';
import MessageScreen from './Message';
import { cSignup } from '../../classes/signup';
import { App } from '../../classes/app';

function Recover() {
  // animation
  const [loginState, setLoginState] = useState("solved" || "pending");
  // message modal
  const [showWindow, setShowWindow] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const [redirect, setRedirect] = useState("");
  const [token, setToken] = useState();
  const [status, setStatus] = useState(false);

  const [password, setPassword] = useState("");

  async function postData(){
    setLoginState("pending");
    setStatus(false);
    setMessage("Processando o pedido");
    setShowWindow(true);

    const login = await (new cSignup()).RecoverNewPassword(password, window.location.pathname.split("/")[2]);
    if(login){
      setMessage(login["message"]);
      if(login["code"] === "RECOVER_EMAIL_SUCCESS"){
        setToken(login["token"]);
        setRedirect("redirect");
        setStatus(true);
      }else{
        if(login["redirect"]) setRedirect(login["redirect"]);
        setStatus(false);
      }
      setShowWindow(true);
    }
    setLoginState("solved");
  }

  return (
    <body className='flex flex-col h-full'>
      <AppHeader link='/'/>
      {((showWindow) ? <MessageScreen redirect={(redirect) ? "/login" : ""} title={title} message={message} hideWindow={setShowWindow} canClose={(status)}/> : <></>)}
      <main className='h-full flex items-center md:justify-start justify-center' style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition : "top"
      }}>
        <div className='flex md:w-[30%] flex-col p-6 md:m-4 md:ml-12 bg-white'>
          <h1 className='text-4xl pb-8 self-center place-self-center'>Nova Senha</h1>
          <input onChange={(e)=> setPassword(e.target.value)}  className='p-2 border-2 mb-2' type="password" placeholder='Nova Senha'/>
          <div className='w-full flex flex-col mt-6'>
            <button onClick={(e)=> (loginState === "solved") ? postData() : 0} 
              className={`${loginState === "pending" ? "animate-pulse" : "hover:bg-blue-200"} text-sm bg-slate-200 mt-2 p-2 flex justify-center content-center`}>Alterar</button>
          </div>
        </div>
        <div></div>
      </main>
      <AppFooter/>
    </body>
  );  
}

export default Recover;