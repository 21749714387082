export class AuthenticationAdmin{
    async LoginAdmin(email : string, password : string){
        const data = await fetch(process.env.REACT_APP_API_ROUTE + "api/admin/signin",{
            method : "post",
            headers : {
                "Accept" : "application/json",
                "Content-Type" : "application/json"
            },
            body : JSON.stringify({
                "email" : email,
                "password" : password
            })
        });
        
        const response = await data.text();
        return JSON.parse(response);
    }
}