import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { AppFooter, AppHeader } from '../../../components/AppHeading';
import { admin_background } from '../../../assets';
import { AuthenticationAdmin } from '../../../classes/authenticationAdmin';

function AdminLogin() {
  // animation
  const [loginState, setLoginState] = useState("solved" || "pending");

  const cookies = new Cookies();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  async function Login() {
    setLoginState("pending");
    const authentication = await (new AuthenticationAdmin()).LoginAdmin(email, password);
    if (authentication["code"] === "LOGIN_SUCCESS") {
      cookies.set('Authentication', authentication["token"]);
      document.location = "/admin/dashboard";
    } else {
      setMessage(authentication["message"])
      setTimeout(() => {
        setMessage('');
      }, 2000);
    }
    setLoginState("solved");
  }

  return (
    <body className='flex flex-col h-full'>
      <AppHeader link='/admin'/>
      <main className='h-full bg-gray-500 flex items-center justify-start' style={{
        backgroundImage: `url(${admin_background})`,
        backgroundSize: "cover",
        backgroundPosition : "top"
      }}>
        <div className='flex md:w-[30%] flex-col p-6 m-4 ml-16 bg-white'>
          <h1 className='text-4xl pb-14 self-center place-self-center'>Administração</h1>
          <p className='text-sm pb-2 self-center place-self-center'>{message}</p>
          <input onChange={(e) => setEmail(e.target.value)} className='p-2 border-2 mb-2 text-sm' type="email" placeholder='Email'/>
          <input onChange={(e) => setPassword(e.target.value)} className='p-2 border-2 text-sm' type="password" placeholder='**********'/>
          {/* <p className='text-sm m-2 text-blue-500 hover:cursor-pointer'>Recuperar Senha</p> */}
          <div className='w-full flex flex-col mt-6'>
            <button onClick={(e) => (loginState === "solved") ? Login() : 0} className={`${loginState === "pending" ? "animate-pulse" : "hover:bg-blue-200"} text-sm bg-slate-200 mt-2 p-2 flex justify-center content-center`} >Iniciar Sessão</button>
          </div>
        </div>
        <div></div>
      </main>
      <AppFooter/>
    </body>
  );  
}

export default AdminLogin;