import { useState } from "react";
import { iPopup } from "../../../../interface.td";
import MessageScreen from "../../../../pages/signup/Message";

function SettingsNewUserBackoffice({ hideWindow } : iPopup){
    const [showWindow, setShowWindow] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    return(
    <div id='messages' className='flex justify-center content-center absolute top-0 left-0 w-full h-full bg-[#00000085]'>
        {(showWindow ? <MessageScreen title={title} message={message} hideWindow={setShowWindow}/> : <></>)}
        <div className='flex justify-center items-center w-[60%]'>
            <div className='flex flex-col bg-white h-fit p-8'>
                <div>
                    <h1 className='text-3xl mb-8'>Novo utilizador para o Backoffice</h1>
                </div>
                <div className='h-full'>
                    <div className="flex flex-wrap">
                        <input className="text-black border-2 border-gray-300 p-2 m-2" type="text" placeholder="Nome"/>
                        <input className="text-black border-2 border-gray-300 p-2 m-2" type="email" placeholder="Email"/>
                        <input className="text-black border-2 border-gray-300 p-2 m-2" type="password" placeholder="Senha"/>
                        <input className="text-black border-2 border-gray-300 p-2 m-2" type="text" placeholder="Telemóvel"/>
                        <select className="text-black border-2 border-gray-300 p-2 m-2" name="" id="">
                            <option value="">INSS</option>
                            <option value="">MAPTSS</option>
                            <option value="">INFEOP</option>
                        </select>
                    </div>
                </div>
                <div className="flex justify-between">
                    <div></div>
                    <div>
                        <button onClick={(e)=> {
                            setTitle("Sucesso!!");
                            setMessage("Novo usuário adicionado");
                            setShowWindow(true)
                        }} className="m-1 p-2 bg-gray-300">Adicionar</button>
                        <button onClick={(e)=> hideWindow(false)} className="m-1 p-2 bg-gray-300">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default SettingsNewUserBackoffice;