import { Link } from "react-router-dom";
import Cookies from "universal-cookie";

function UserMenu(){
    const cookie = new Cookies();

    return (
        <ul className="flex md:flex-col justify-between">
            <li className='p-1 mb-2 md:bg-slate-200 hover:bg-slate-700 hover:text-white'>Status da Candidatura</li>
            <button className='p-2 md:mb-2 md:bg-slate-200 bg-red-300 hover:bg-slate-700 hover:text-white md:w-full text-left' onClick={(e)=>{
                cookie.remove("Authentication",{ path : "/" });
                document.location = "/";
            }}>
                Sair
            </button>
        </ul>
    );
}

export default UserMenu;