import { Dispatch, SetStateAction } from "react";
import SubmissionRole from "./userDashboard/submission/submissionRoleC";

interface iSubmissionRules{
  setShowRules : Dispatch<SetStateAction<boolean>>
}

export function SubmissionRules(props : iSubmissionRules){
  return (<div className="z-50 fixed top-0 left-0 bg-[#00000085] w-full h-full flex justify-center">
    <div className='flex w-4/5 h-[90%] flex-col p-6 m-4 md:ml-12 bg-white'>
      <SubmissionRole/>
      <button onClick={(e)=> props.setShowRules(false)} className="self-end m-1 bg-slate-100 pl-12 pr-12 hover:bg-blue-200 mt-2 p-2 flex ">Fechar</button>
    </div>
  </div>);
}