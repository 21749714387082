import { useEffect, useState } from "react";
import SettingsNewUserBackoffice from "./components/swc_newUserBackoffice";
import { iAdminList } from "../../../interface.td";
import { deleteUser } from "../../../functions/admin";
import { AdminDashboardClass } from "../../../classes/admin";
import Cookies from "universal-cookie";

function SwcUsers(){
    const cookies = new Cookies();
    const [showModal, setShowModal] = useState(false);
    const [adminList, setAdminList] = useState<iAdminList[]>([]);

    async function fetchAdminList() {
        const data = await (new AdminDashboardClass()).getManagerList(cookies.get("Authentication"),"manager");
        setAdminList(data);
    }

    useEffect(()=>{
        fetchAdminList();
    },[]);

    return (
        <div>
            {(showModal ? <SettingsNewUserBackoffice hideWindow={setShowModal}/> : <></>)}
            <div>
                <div>
                    <h1 className="m-2 font-bold text-lg pb-4">Utilizadores do Backoffice</h1>
                </div>
                <div>
                    <ul>
                        {adminList.map((item,key)=> <li key={key} className="flex justify-between m-2">
                            <div>{key + 1}</div>
                            <div>{item.name}</div>
                            <div>{item.email}</div>
                            <div>{item.role}</div>
                            <button className="bg-red-300 pl-2 pr-2 p-1" onClick={async(e)=>{
                                await deleteUser(item.id, cookies.get("Authentication"));
                                await fetchAdminList();
                            }}>Apagar</button>
                        </li> )}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default SwcUsers;