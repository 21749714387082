import { useState } from "react";
import { iPopup } from "../../../../interface.td";
import MessageScreen from "../../../../pages/signup/Message";

function SettingsNewCampaign({ hideWindow } : iPopup){
    const [showWindow, setShowWindow] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [availableSub, setAvailableSub] = useState(0);

    return(
    <div id='messages' className='flex justify-center content-center absolute top-0 left-0 w-full h-full bg-[#00000085]'>
        {(showWindow ? <MessageScreen title={title} message={message} hideWindow={setShowWindow}/> : <></>)}
        <div className='flex justify-center items-center w-[50%]'>
            <div className='flex flex-col bg-white h-fit p-8'>
                <div>
                    <h1 className='text-3xl mb-8'>Nova Campanha</h1>
                </div>
                <div className='h-full'>
                    <div className="flex flex-col">
                        <input className="text-black border-2 border-gray-300 p-2 m-2" type="text" placeholder="Nome"/>
                        <p className="text-black m-2">Data de início</p>
                        <input className="text-black border-2 border-gray-300 p-2 m-2" type="date" placeholder="Data de Início"/>
                        <p className="text-black m-2">Data de fim</p>
                        <input className="text-black border-2 border-gray-300 p-2 m-2" type="date" placeholder="Data de Término"/>
                        <input onChange={(e)=> setAvailableSub(Number(e.target.value))} className="text-black border-2 border-gray-300 p-2 m-2" type="number" placeholder="Limite de vagas"/>
                        <p className="text-black m-2">Vagas por entidade: {(!isNaN(availableSub) ? availableSub : 0)}</p>
                    </div>
                </div>
                <div className="flex justify-between">
                    <div></div>
                    <div>
                        <button onClick={(e)=> {
                            setTitle("Sucesso!!");
                            setMessage("Novo usuário adicionado");
                            setShowWindow(true)
                        }} className="m-1 p-2 bg-gray-300">Aprovar</button>
                        <button onClick={(e)=> hideWindow(false)} className="m-1 p-2 bg-gray-300">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default SettingsNewCampaign;