import { useEffect, useState } from "react";
import { iPopup, iSubmissionData } from "../../interface.td";
import MessageScreen from "../../pages/signup/Message";
import { AdminDashboardClass } from "../../classes/admin";
import Cookies from "universal-cookie";

interface submissionInfo{
    "id": number,
    "userProfile": {
        "id": number,
        "startingYear": Date,
        "verified": false,
        "organizationDatabase": {
            "id": number,
            "name": string,
            "bi": string,
            "email": string,
            "salary": number,
            "complement": number,
            "already_house": boolean,
            "have_debt": boolean,
            "debt_length": number,
            "debt_value": number,
            "disciplinary_process" : boolean,
            "updatedAt": Date,
            "organization": {
                "id": number,
                "name": string
            },
            "role": {
                "id": number,
                "name": string
            },
            "category": {
                "id": number,
                "name": string
            }
        }
    }
}

function CandidateAprove({ hideWindow, id, setRemoteShowWindow, setThisShowWindow, setRemoteTitle, setRemoteMessage, callback } : iPopup){
    const cookies = new Cookies();
    const [showWindow, setShowWindow] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [submissionData, setSubmissionData] = useState<submissionInfo>();

    // field to approve and recuse
    const [organization_status, setOrganization_status] = useState("approved");
    const [salary_status, setSalary_status] = useState("approved");
    const [startingYear_status, setStartingYear_status] = useState("approved");
    const [role_status, setRole_status] = useState("approved");
    const [category_status, setCategory_status] =useState("approved");
    const [bi_status, setBi_status] = useState("approved");
    const [submissionDate, setSubmissionDate] = useState("approved");
    const [submissionStatus, setSubmissionStatus] = useState("approved");
    const [base_salary, setBase_salary] = useState("approved");
    const [have_debt, setHave_debt] = useState("approved");
    const [debt_value, setDebt_value] = useState("approved");
    const [debt_length, setDebt_length] = useState("approved");

    // submitting, we shouldn't be allowed to send another request
    const [sendingStatus, setSendingStatus] = useState("solved" || "pending");

    // botão de recusar 
    const [r_message, setRMessage] = useState("");
    const [startReject, setStartReject] = useState(false);

    async function submitCandidature(status : string){
        setSendingStatus("pending");

        const response = await (new AdminDashboardClass()).approveCandidature(
            organization_status,
            salary_status,
            startingYear_status,
            role_status,
            category_status,
            bi_status,
            submissionDate,
            submissionStatus,
            base_salary,
            id || 0,
            status,
            have_debt,
            debt_value,
            debt_length,
            cookies.get("Authentication")
        );

        if(response["code"] === "ADMIN_ACTION_SUBMISSION_SUCCESS"){
            if(setRemoteTitle) setRemoteTitle("Sucesso!!");
            if(setRemoteMessage) setRemoteMessage(response["message"]);
            if(setThisShowWindow) setThisShowWindow(false);
            if(setRemoteShowWindow) setRemoteShowWindow(true);
            if(callback) await callback();
        }

        setSendingStatus("solved");
    }

    useEffect(()=>{
        setRMessage("Assinalar e recusar");
        async function fetchData(){
            if(id){
                const response = await (new AdminDashboardClass()).viewCandidature(id,cookies.get("Authentication"));
                setSubmissionData(response);
            }
        }
        fetchData();
    },[id]);

    return(
    <div id='messages' className="fixed left-0 top-0 flex justify-center content-center w-full h-full bg-[#00000085]">
        {(showWindow ? <MessageScreen title={title} message={message} hideWindow={setShowWindow}/> : <></>)}
        <div className='flex justify-center items-center w-[80%]'>
            <div className='flex flex-col bg-white h-fit p-8'>
                <div>
                    <h1 className='text-3xl mb-8'>{submissionData?.userProfile.organizationDatabase.name}</h1>
                </div>
                <div className='h-full'>
                    <div className="flex flex-wrap">
                        <ul className="flex flex-wrap">
                            <li className="flex flex-col">
                                <p className="text-black m-1 text-sm">Nome</p>
                                <input disabled className="text-black border-2 border-gray-300 p-2 m-2 text-sm" type="text" placeholder={submissionData?.userProfile.organizationDatabase.name}/>
                            </li>
                            <li className="flex flex-col">
                                <p className="text-black m-1 text-sm">Email</p>
                                <input disabled className="text-black border-2 border-gray-300 p-2 m-2 text-sm" type="text" placeholder={submissionData?.userProfile.organizationDatabase.email}/>
                            </li>
                            <li className="flex flex-col">
                                <p className="text-black m-1 text-sm">Organização</p>
                                <input disabled className="text-black border-2 border-gray-300 p-2 m-2 text-sm" type="text" placeholder={submissionData?.userProfile.organizationDatabase.organization.name}/>
                                <input onChange={(e)=> setOrganization_status((previous)=> e.target.checked === true ? "rejected" : "approved")} className={`${(startReject) ? "" : "hidden"} text-black border-2 border-gray-300 p-2 m-2 text-sm`} type="checkbox" name="" id="" />
                            </li>
                            <li className="flex flex-col">
                                <p className="text-black m-1 text-sm">Função</p>
                                <input disabled className="text-black border-2 border-gray-300 p-2 m-2 text-sm" type="text" placeholder={submissionData?.userProfile.organizationDatabase.role.name}/>
                                <input onChange={(e)=> setRole_status((previous)=> e.target.checked === true ? "rejected" : "approved")} className={`${(startReject) ? "" : "hidden"} text-black border-2 border-gray-300 p-2 m-2 text-sm`} type="checkbox" name="" id="" />
                            </li>
                            <li className="flex flex-col">
                                <p className="text-black m-1 text-sm">Categoria</p>
                                <input disabled className="text-black border-2 border-gray-300 p-2 m-2 text-sm" type="text" placeholder={submissionData?.userProfile.organizationDatabase.category.name}/>
                                <input onChange={(e)=> setCategory_status((previous)=> e.target.checked === true ? "rejected" : "approved")} className={`${(startReject) ? "" : "hidden"} text-black border-2 border-gray-300 p-2 m-2 text-sm`} type="checkbox" name="" id="" />
                            </li>
                            <li className="flex flex-col">
                                <p className="text-black m-1 text-sm">Data de admissão</p>
                                <input disabled className="text-black border-2 border-gray-300 p-2 m-2 text-sm" type="text" placeholder={String((submissionData?.userProfile.startingYear ? (new Date(submissionData.userProfile.startingYear)).getDate() : 0))}/>
                                <input onChange={(e)=> setStartingYear_status((previous)=> e.target.checked === true ? "rejected" : "approved")} className={`${(startReject) ? "" : "hidden"} text-black border-2 border-gray-300 p-2 m-2 text-sm`} type="checkbox" name="" id="" />
                            </li>
                            <li className="flex flex-col">
                                <p className="text-black m-1 text-sm">Bilhete de identidade</p>
                                <input disabled className="text-black border-2 border-gray-300 p-2 m-2 text-sm" type="text" placeholder={submissionData?.userProfile.organizationDatabase.bi}/>
                                <input onChange={(e)=> setBi_status((previous)=> e.target.checked === true ? "rejected" : "approved")} className={`${(startReject) ? "" : "hidden"} text-black border-2 border-gray-300 p-2 m-2 text-sm`} type="checkbox" name="" id="" />
                            </li>
                            <li className="flex flex-col">
                                <p className="text-black m-1 text-sm">Salário Líquido</p>
                                <input disabled className="text-black border-2 border-gray-300 p-2 m-2 text-sm" type="text" placeholder={String(submissionData?.userProfile.organizationDatabase.salary)}/>
                                <input onChange={(e)=> setSalary_status((previous)=> e.target.checked === true ? "rejected" : "approved")} className={`${(startReject) ? "" : "hidden"} text-black border-2 border-gray-300 p-2 m-2 text-sm`} type="checkbox" name="" id="" />
                            </li>
                            <li className="flex flex-col">
                                <p className="text-black m-1 text-sm">Suplemento</p>
                                <input disabled className="text-black border-2 border-gray-300 p-2 m-2 text-sm" type="text" placeholder={String(submissionData?.userProfile.organizationDatabase.complement)}/>
                                <input onChange={(e)=> setBase_salary((previous)=> e.target.checked === true ? "rejected" : "approved")} className={`${(startReject) ? "" : "hidden"} text-black border-2 border-gray-300 p-2 m-2 text-sm`} type="checkbox" name="" id="" />
                            </li>
                            <li className="flex flex-col">
                                <p className="text-black m-1 text-sm">Possui divida</p>
                                <input disabled className="text-black border-2 border-gray-300 p-2 m-2 text-sm" type="text" placeholder={submissionData?.userProfile.organizationDatabase.have_debt ? "Sim" : "Não"}/>
                                <input onChange={(e)=> setHave_debt((previous)=> e.target.checked === true ? "rejected" : "approved")} className={`${(startReject) ? "" : "hidden"} text-black border-2 border-gray-300 p-2 m-2 text-sm`} type="checkbox" name="" id="" />
                            </li>
                            <li className="flex flex-col">
                                <p className="text-black m-1 text-sm">Valor da Dívida</p>
                                <input disabled className="text-black border-2 border-gray-300 p-2 m-2 text-sm" type="text" value={submissionData?.userProfile.organizationDatabase.debt_value}/>
                                <input onChange={(e)=> setDebt_value((previous)=> e.target.checked === true ? "rejected" : "approved")} className={`${(startReject) ? "" : "hidden"} text-black border-2 border-gray-300 p-2 m-2 text-sm`} type="checkbox" name="" id="" />
                            </li>
                            <li className="flex flex-col">
                                <p className="text-black m-1 text-sm">Meses até pagar</p>
                                <input disabled className="text-black border-2 border-gray-300 p-2 m-2 text-sm" type="text" placeholder={String(submissionData?.userProfile.organizationDatabase.debt_length)}/>
                                <input onChange={(e)=> setDebt_length((previous)=> e.target.checked === true ? "rejected" : "approved")} className={`${(startReject) ? "" : "hidden"} text-black border-2 border-gray-300 p-2 m-2 text-sm`} type="checkbox" name="" id="" />
                            </li>
                        </ul>  
                    </div>
                </div>
                <div className="flex justify-between">
                    <div>
                        <button onClick={(e)=> {
                            setStartReject((previous)=> (previous === false) ? true : false);
                            setSubmissionStatus((previous)=> (previous === "approved") ? "approved" : "rejected");
                        }} className={`m-1 p-2 ${((startReject === false) ? "bg-red-300" : "bg-green-200")}`}>{((startReject === false) ? "Revisar e Rejeitar" : "Cancelar")}</button>
                    </div>
                    <div>
                        <button onClick={(e)=> {
                            if(sendingStatus === "solved"){
                                if(startReject === true) submitCandidature("reject");
                                else submitCandidature("accept");
                            }
                        } } className={`m-1 p-2 ${((startReject === false) ? "bg-green-300" : "bg-red-300")} ${(sendingStatus == "pending" ? "!bg-white !text-black" : "")} ${(sendingStatus === "solved") ? "relative" : "disabled"}`}>{((startReject === false) ? "Aceitar Candidatura" : "Rejeitar")}</button>
                        <button onClick={(e)=> hideWindow(false)} className="m-1 p-2 bg-gray-300">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default CandidateAprove;