export function FaqText(){
    return (
        <>
            <div>
                <h1 className='text-3xl mb-5'>Perguntas Frequentes (FAQ)</h1>
            </div>
            <div className='h-[80%] overflow-y-scroll'>
                <div className="mb-2">
                </div>
                <div className="mb-2">
                    <h2 className="font-bold text-xl m-4">1. Para que serve a ferramenta?</h2>
                    <p>
                        &emsp;A ferramenta serve para submissão de candidaturas a imóveis na modalidade de renda resolúvel do empreendimento Torres Eucaliptos. Ela permite que os funcionários públicos e agentes públicos vinculados ao MAPTSS e aos órgãos por ele superintendidos, residentes e que desenvolvem as suas atividades na Província de Luanda, possam se candidatar a esses imóveis.
                    </p>
                </div>
                <div className="mb-2">
                    <h2 className="font-bold text-xl m-4">2. Quais os requisitos para candidatura?</h2>
                    <p>
                        &emsp;Para se candidatar, o candidato deve ser:
                    </p>
                    <ul className="ml-8 list-disc list-inside">
                        <li>Funcionário público ou agente público em comissão de serviço vinculado materialmente ao MAPTSS e aos órgãos por ele superintendidos.</li>
                        <li>Residente e com atividade desenvolvida na Província de Luanda.</li>
                        <li>Aferir uma remuneração superior ou igual a Kz. 680 100,00 (Seiscentos e oitenta mil e cem kwanzas).</li>
                    </ul>
                </div>
                <div className="mb-2">
                    <h2 className="font-bold text-xl m-4">3. Como funciona?</h2>
                    <p>
                        &emsp;A ferramenta é acessível via internet. Para utilizar a ferramenta, siga os seguintes passos:                
                    </p>
                    <ol className="ml-8 list-decimal list-inside">
                        <li>Acesse o link: https://front-sema.maptss.gov.ao/</li>
                        <li>Crie uma conta inserindo seu email institucional e definindo uma senha robusta (mais de 8 caracteres, contendo letras maiúsculas, minúsculas e símbolos numéricos ou não numéricos).</li>
                        <li>Clique no botão "Criar conta".</li>
                        <li>Caso atenda os requisitos será enviado o código de verificação para o seu email corporativo, deverá inserir o código e aceder à sua conta.                        </li>
                    </ol>
                </div>
                <div className="mb-2">
                    <h2 className="font-bold text-xl m-4">4. Quem não está elegível a candidatar-se?</h2>
                    <p>&emsp;Não estão elegíveis a candidatar-se os funcionários públicos ou agentes públicos que:</p>
                    <ol className="ml-8 list-disc list-inside">
                        <li>Sejam titulares de residências no regime de compra liquidatária ou a renda resolúvel no âmbito da política habitacional do setor ou da política habitacional do Estado por intermédio do MAPTSS.</li>
                        <li>Aufiram um rendimento mensal no sector inferior a Kz. 680 100,00 (Seiscentos e oitenta mil e cem kwanzas).</li>
                        <li>Tenham sido alvos de processos disciplinares nos últimos 24 meses.</li>
                    </ol>
                    <p>Nota: Basta que um dos critérios acima seja atendido para que o candidato não seja elegível.</p>
                </div>
                <div className="mb-2">
                    <h2 className="font-bold text-xl m-4">5. Quem está elegível a se candidatar?</h2>
                    <p>
                        &emsp;Estão elegíveis a se candidatar os funcionários públicos e agentes públicos em comissão de serviço vinculados materialmente ao MAPTSS e aos órgãos por ele superintendidos, residentes e que desenvolvam as suas atividades na Província de Luanda, que não se enquadrem em nenhum dos critérios de inelegibilidade mencionados.
                    </p>
                </div>
                <div className="mb-2">
                    <h2 className="font-bold text-xl m-4">6. Caso encontre dificuldades no processo de candidatura.</h2>
                    <p>
                        &emsp;Em caso de não conformidade com os seus dados expostos no sistema, entre em contacto com a administração do seu órgão ou remeta a reclamação com anexando as evidências das informações a serem revisadas para o email: candidatura.reclamacao@maptss.gov.ao .
                    </p>
                </div>
                <div className="mt-6 text-center text-sm">
                    Comissão de Trabalho, em Luanda aos 10 de Abril de 2024.
                </div>
            </div>
        </>
    )
}
