import { useEffect, useState } from "react";
import gov from "../assets/thumbs-logo-governo.png";
import govmaptss from "../assets/thumbs-logo-maptss.png";
import { SubmissionRules } from "./submissionRules";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { Faq } from "./faq";

interface redirectLink{
  link : string
}

export function AppHeader(props : redirectLink) {
  const cookie = new Cookies();
  const [showRules, setShowRules] = useState(false);
  const [showFaq, setShowFaq] = useState(false);
  const [logged, setLogged] = useState(false);

  useEffect(()=>{
      const session = cookie.get("Authentication");
      if(session) setLogged(true);
      else setLogged(false);
  },[]);

  return (<>
  {(showRules) ? <SubmissionRules setShowRules={setShowRules}/> : <></>}
  {(showFaq) ? <Faq setShowRules={setShowFaq}/> : <></>}
  <header>
    <div className='flex justify-between p-6 bg-slate-100'>
      <div className='flex'>
        <img style={{
          width: "15%"
        }} src={gov} alt="" />
        <img style={{
          width: "15%",
          marginLeft: "1%"
        }} src={govmaptss} alt="" />
      </div>
      <div></div>
    </div>
    <ul className="flex bg-[#2662AF] pl-10 md:text-[0.8rem]">
      {(logged === false) ? <Link to={props.link}>
        <li className="pl-4 p-2 bg-white select-none">Página Inicial</li>
      </Link> : <></>}
      {(logged === true) ? <li onClick={(e)=> setShowRules(true)} className="pl-4 p-2 hover:bg-white hover:text-black text-white select-none">
        Regulamento
      </li> : <></>}
      <li onClick={(e)=> setShowFaq(true)} className="pl-4 p-2 hover:bg-white hover:text-black text-white select-none">
        Perguntas Frequentes
      </li>
    </ul>
  </header>
  </>);
}

export function AppFooter(){
  return (
  <footer className='md:fixed relative md:bottom-0 bg-[#2662AF] p-2 w-full text-center text-white md:text-sm text-[0.6rem]'>
    <p>© Copyright {(new Date().getFullYear())} | Ministério da Administração Pública Trabalho e Segurança Social - MAPTSS</p>
  </footer>);
}