import React, { useEffect, useState } from 'react';
import SubmissionRole from '../../../components/userDashboard/submission/submissionRoleC';
import SubmissionForm from '../../../components/userDashboard/submission/submissionForm';
import { AppFooter, AppHeader } from '../../../components/AppHeading';
import Cookies from 'universal-cookie';
import { parseJwt } from '../../../functions/jwt';

function UserSubmission(){
    const cookies = new Cookies();
    const [stepRoute, setStepRoute] = useState(0);
    const [canProceed, setCanProceed] = useState(false);
    const [hidden, setHidden] = useState(false);

    const route = [
        {
            "message" : "Aceitar",
            "component" : <SubmissionRole/>,
            "function" : ()=> { return false; }
        },
        {
            "message" : "Prosseguir",
            "component" : <SubmissionForm canProceed={canProceed} setCanProceed={setCanProceed} setHidden={setHidden} setStepRoute={setStepRoute}/>,
            "function" : ()=> { return false; }
        },
        {
            "message" : "Enviando...",
            "component" : <></>,
            "function" : ()=>{
                setTimeout(()=>{
                    document.location = "/app/dashboard"
                },3000)
                return true;
            }
        }
    ];

    useEffect(()=>{
        const parseCookie = cookies.get("Authentication");
        const isAdmin = parseJwt(parseCookie);
        if(isAdmin["role"]){
            document.location = "/admin";
        }
    },[]);

    return (
    <body className='flex flex-col h-full'>
        <AppHeader link='/'/>
        <main className='h-auto md:h-full bg-gray-500 flex justify-center'>
            <div className='flex md:mb-14 md:w-4/5 flex-col p-6 md:m-4 mt-2 md:ml-12 bg-white'>
                {/* This is crap (by the way the entire code!), but i didn't had enough time to make an better code, gov sucks 😔 */}
                {(route[stepRoute].function())}
                {route[stepRoute].component}
                <div className='flex justify-between'>
                    {(stepRoute === 0) ? <button onClick={(e)=>{
                        cookies.remove("Authentication",{path : "/"});
                        document.location = "/";
                    }} className='md:p-2 pl-6 m-2 md:pr-6 bg-red-400 text-white'>Recusar</button> : <div></div>}
                    {(hidden === false) ? <button onClick={(e)=> {
                        if(!(stepRoute === 1 && canProceed === false)){
                            setStepRoute((stepRoute)=> stepRoute + 1)
                        }
                    }} className='md:p-2 pl-6 m-2 md:pr-6 bg-gray-400'>{route[stepRoute].message}</button> : <></>}
                </div>
            </div>
        </main>
        <AppFooter/>
    </body>
    );  
}

export default UserSubmission;