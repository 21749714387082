export class Authentication{
    async Login(email : string, password : string){
        const data = await fetch(process.env.REACT_APP_API_ROUTE + "api/signin",{
            method : "post",
            headers : {
                "Accept" : "application/json",
                "Content-Type" : "application/json"
            },
            body : JSON.stringify({
                "email" : email,
                "password" : password
            })
        });
        
        const response = await data.text();
        return JSON.parse(response);
    }

    async CheckVerified(token : string){
        const data = await fetch(process.env.REACT_APP_API_ROUTE + "api/submission/accountStatus",{
            method : "get",
            headers : {
                "Accept" : "application/json",
                "Content-Type" : "application/json",
                "Authentication" : token
            }
        });

        const response = await data.text();
        return JSON.parse(response);
    }
}