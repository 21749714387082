import { useEffect, useState } from 'react';
import AdminMenu from '../../../components/adminDashboard/adminMenu';
import CandidaturesWindow from '../../../components/adminDashboard/candidatureWindow';
import SettingsWindow from '../../../components/adminDashboard/settingsWindow';
import UsersWindow from '../../../components/adminDashboard/usersWindow';
import { AppFooter, AppHeader } from '../../../components/AppHeading';
import { admin_background } from '../../../assets';
import Cookies from 'universal-cookie';
import { parseJwt } from '../../../functions/jwt';

function AdminDashboard() {
    const [menuRouter, setMenuRouter] = useState(0);
    const [role,setRole] = useState("");
    const [name,setName] = useState("");
    const cookie = new Cookies();

    const menu = [
        <CandidaturesWindow/>,
        <SettingsWindow/>,
        <UsersWindow/>
    ];

    useEffect(()=>{
        const jwt = parseJwt(cookie.get("Authentication"));
        if(jwt){
            setRole((e)=> (jwt["role"] === "admin" ? "Administrador" : "Comissão"));
            if(jwt["role"] === "admin") setMenuRouter(1);
            setName(jwt["name"]);
        }
    },[]);

    return (
    <body className='flex flex-col h-full'>
        <AppHeader link='/admin/dashboard'/>
        <main className='flex items-center justify-center' style={{
            backgroundImage: `url(${admin_background})`,
            backgroundSize: "cover",
            backgroundPosition : "top"
        }}>
            <div className='flex w-[90%] flex-col p-6 m-4 ml-12 bg-white mb-[5%]'>
                <div className='flex justify-between content-center items-center'>
                    <h1 className='text-3xl mb-5'>{role}</h1>
                    <p>{name}</p>
                </div>
                <div className='flex'>
                    <div className='w-[20%] mr-2 text-sm'>
                        <AdminMenu menuRouter={menuRouter} setMenuRouter={setMenuRouter}/>
                    </div>
                    <div className='w-[80%]'>
                        {menu[menuRouter]}
                    </div>
                </div>
            </div>
            <div></div>
        </main>
        <AppFooter/>
    </body>
    );  
}

export default AdminDashboard;