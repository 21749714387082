import { useState } from "react";
import SwcCampaign from "./settingsWindowComponents/swc_campaign";
import SwcOrganization from "./settingsWindowComponents/swc_organization";
import SwcGeneral from "./settingsWindowComponents/swc_general";
import SwcUsers from "./settingsWindowComponents/swc_user";

function SettingsWindow(){
    const [settingsMenuSeparator, setSettingsMenuSeparator] = useState(0);

    const settingsWindowRouter = [
        <SwcGeneral/>,
        <SwcCampaign/>,
        <SwcOrganization/>,
        <SwcUsers/>
    ];

    return(
        <div className="text-sm">
            <div>
                <ul className="flex">
                    <li onClick={(e)=> 
                        setSettingsMenuSeparator(0)
                    } className={`p-2 ${(settingsMenuSeparator === 0 ? "bg-gray-500 text-white" : "bg-gray-200")}`}>Gerais</li>
                    {/*
                    <li onClick={(e)=> 
                        setSettingsMenuSeparator(1)
                    }  className={`p-2 ${(settingsMenuSeparator === 1 ? "bg-gray-500 text-white" : "bg-gray-200")}`}>Campanhas</li>
                    <li onClick={(e)=> 
                        setSettingsMenuSeparator(2)
                    }  className={`p-2 ${(settingsMenuSeparator === 2 ? "bg-gray-500 text-white" : "bg-gray-200")}`}>Organizações</li>
                    */}
                    <li onClick={(e)=> 
                        setSettingsMenuSeparator(3)
                    }  className={`p-2 ${(settingsMenuSeparator === 3 ? "bg-gray-500 text-white" : "bg-gray-200")}`}>Utilizadores</li>
                </ul>
            </div>
            {settingsWindowRouter[settingsMenuSeparator]}
        </div>
    )
}

export default SettingsWindow;