import { useEffect, useState } from "react";
import { iAccountData, iSubmissionFormState } from "../../../interface.td";
import Cookies from "universal-cookie";
import { App } from "../../../classes/app";

interface listItem{
    id : number,
    name : string
}

function SubmissionForm({ canProceed, setCanProceed, setHidden, setStepRoute } : iSubmissionFormState){
    const cookies = new Cookies();
    const [institute, setInstitute] = useState(1);
    const [startYear, setStartYear] = useState(Date);
    const [role, setRole] = useState(1);
    const [category, setCategory] = useState(1);
    const [bi, setBi] = useState('');
    const [institutions, setInstitutions] = useState<listItem[]>([]);
    const [roles, setRoles] = useState<listItem[]>([]);
    const [categories, setCategories] = useState<listItem[]>([]);

    const [autoFill, setAutofill] = useState<iAccountData>();

    // mailto configuration
    const configuration = `mailto:candidatura.reclamacao@maptss.gov.ao?cc=${autoFill?.organizationDatabase.email}&subject=Solicitação de Revisão dos Dados&body=Saudações Prezados, gostaria de solicitar a revisão dos dados da minha candidatura. Meus dados são:
        * ID: ${autoFill?.organizationDatabase.id}
        * Nome: ${autoFill?.organizationDatabase.name}
        * Instituição: ${autoFill?.organizationDatabase.organization.name}
        * Email: ${autoFill?.organizationDatabase.email}
        * BI: ${autoFill?.organizationDatabase.bi}
        Atenciosamente
    `;

    useEffect(()=>{
        async function fillMissingData(){
            const data_institutes = await (new App()).getInstitutes();
            setInstitutions(data_institutes);
            setInstitute(data_institutes[0].id);

            const data_roles = await (new App()).getRoles();
            setRoles(data_roles);
            setRole(data_roles[0].id);
            
            const data_categories = await (new App()).getCategories();
            setCategories(data_categories);
            setCategory(data_categories[0].id);

            const auto = await (new App()).submissionAccountStatus(cookies.get("Authentication"));
            setAutofill(auto);
        }
        if(setHidden) setHidden(true);
        fillMissingData();
    },[setHidden])

    async function sendData(){
        const parseCookie = cookies.get("Authentication");
        const submission = await (new App()).submissionRequest(bi,parseCookie);
        if(submission["code"] === "ACCOUNT_REQUEST_SUBMISSION_SUCCESS"){
            setCanProceed(true);
            if(setHidden) setHidden(false);
            if(setStepRoute) setStepRoute(2);
        }else{
            alert(submission["message"])
        }
    }

    return (<>   
        <div>
            <h1 className='text-3xl mb-5'>Validação dos Dados</h1>
        </div>
        <div className='flex flex-col'>
            <h3 className="text-sm m-2 pb-4">Favor validar os dados da sua candidatura antes de a submeter.</h3>
            <ul className="flex flex-wrap">
                <li>
                    <p className="text-black m-2 text-sm">Nome</p>
                    <input disabled className="text-sm text-black border-[0.05rem] border-black p-2 m-2" type="text" value={autoFill?.organizationDatabase.name}/>
                </li>
                <li>
                    <p className="text-black m-2 text-sm">Email</p>
                    <input disabled className="text-sm text-black border-[0.05rem] border-black p-2 m-2" type="email" value={autoFill?.organizationDatabase.email}/>
                </li>
                <li>
                    <p className="text-black m-2 text-sm">Organização</p>
                    <input disabled className="text-sm text-black border-[0.05rem] border-black p-2 m-2" type="text" value={autoFill?.organizationDatabase.organization.name}/>
                </li>
                <li>
                    <p className="text-black m-2 text-sm">Renumeração</p>
                    <input disabled className="text-sm text-black border-[0.05rem] border-black p-2 m-2" type="number" value={Number(autoFill?.organizationDatabase.complement) + Number(autoFill?.organizationDatabase.salary)}/>
                </li>
                {/*
                <li>
                    <input disabled onChange={(e)=> setStartYear(e.target.value)} className="text-black border-[0.05rem] border-black p-2 m-2" type="text" value={1 (autoFill.startingYear.getUTCDate()) />
                </li>*/}
                <li>
                    <p className="text-black m-2 text-sm">Cargo</p>
                    <input disabled className="text-sm text-black border-[0.05rem] border-black p-2 m-2" name="" id="" value={autoFill?.organizationDatabase.role.name}/>
                </li>
                <li>
                    <p className="text-black m-2 text-sm">Categoria</p>
                    <input disabled className="text-sm text-black border-[0.05rem] border-black p-2 m-2" name="" value={autoFill?.organizationDatabase.category.name} />
                </li>
                <li>
                    <p className="text-black m-2 text-sm">Identificação</p>
                    <input disabled className="text-sm text-black border-[0.05rem] border-black p-2 m-2" type="text" value={autoFill?.organizationDatabase.bi}/>
                </li>
                <li>
                    <p className="text-black m-2 text-sm">Possui Dívida?</p>
                    <input disabled className="text-sm text-black border-[0.05rem] border-black p-2 m-2" type="text" value={autoFill?.organizationDatabase.have_debt ? "Sim" : "Não"}/>
                </li>
                <li>
                    <p className="text-black m-2 text-sm">Nº de Prestações por Pagar</p>
                    <input disabled className="text-sm text-black border-[0.05rem] border-black p-2 m-2" type="text" value={autoFill?.organizationDatabase.debt_length}/>
                </li>
                <li>
                    <p className="text-black m-2 text-sm">Valor do Empréstimo</p>
                    <input disabled className="text-sm text-black border-[0.05rem] border-black p-2 m-2" type="text" value={autoFill?.organizationDatabase.debt_value}/>
                </li>
            </ul>
        </div>
        <div className="flex justify-between">
            <div className="flex">
                <button onClick={(e)=> {
                    cookies.remove("Authentication");
                    document.location = "/";
                }} className='p-2 pl-6 pr-6 text-white bg-red-500 self-end'>Sair</button>
                <button onClick={(e)=> {
                    window.location.href = configuration;
                    e.preventDefault();
                }} className='ml-2 p-2 pl-6 pr-6 text-white bg-green-500 self-end'>Pedir Revisão</button>
            </div>
            <button onClick={(e)=> sendData()} className='p-2 pl-6 pr-6 text-white bg-green-500 self-end'>Enviar Dados</button>
        </div>
        
    </>)
}

export default SubmissionForm;
