import { useEffect, useState } from "react";
import { iOrganization, iPopup } from "../../../../interface.td";
import MessageScreen from "../../../../pages/signup/Message";
import { App } from "../../../../classes/app";
import { AdminDashboardClass } from "../../../../classes/admin";
import Cookies from "universal-cookie";

function SettingsNewUser({ hideWindow } : iPopup){
    const [showWindow, setShowWindow] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const cookie = new Cookies();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phonenumber, setPhonenumber] = useState(0);
    const [organization_r, setOrganization_r] = useState(0);
    const [role, setRole] = useState("admin"); // default

    const [organization, setOrganization] = useState<iOrganization[]>([]);

    useEffect(()=>{
        async function requestData(){
            const organizations = await (new App()).getInstitutes();
            if(organizations) {
                setOrganization_r(organizations[0].id);
                setOrganization(organizations)
            };
        }
        requestData();
    },[])

    async function createUser(){
        const response = await (new AdminDashboardClass()).createUser(email, password, phonenumber, role, name, organization_r, cookie.get("Authentication"));
        setTitle("Mensagem");
        setMessage(response["message"]);
        setShowWindow(true);
    }

    return(
    <div id='messages' className='flex justify-center content-center absolute top-0 left-0 w-full h-full bg-[#00000085]'>
        {(showWindow ? <MessageScreen title={title} message={message} hideWindow={setShowWindow}/> : <></>)}
        <div className='flex justify-center items-center w-[60%]'>
            <div className='flex flex-col bg-white h-fit p-8'>
                <div>
                    <h1 className='text-3xl mb-8'>Novo utilizador</h1>
                </div>
                <div className='h-full'>
                    <div className="flex flex-wrap">
                        <input onChange={(e)=> setName(e.target.value)} className="text-black border-2 border-gray-300 p-2 m-2" type="text" placeholder="Nome"/>
                        <input onChange={(e)=> setEmail(e.target.value)} className="text-black border-2 border-gray-300 p-2 m-2" type="email" placeholder="Email"/>
                        <input onChange={(e)=> setPassword(e.target.value)} className="text-black border-2 border-gray-300 p-2 m-2" type="password" placeholder="Senha"/>
                        <input onChange={(e)=> setPhonenumber(Number(e.target.value))} className="text-black border-2 border-gray-300 p-2 m-2" type="text" placeholder="Telemóvel"/>
                        <select onChange={(e)=> setRole(e.target.value)} className="text-black border-2 border-gray-300 p-2 m-2" name="" id="">
                            <option selected value="admin">Administrador</option>
                            <option value="moderator">Moderador</option>
                        </select>
                        <select onChange={(e)=> setOrganization_r(Number(e.target.value))} className="text-black border-2 border-gray-300 p-2 m-2" name="" id="">
                            {organization.map((item,key)=> <option key={key} value={item.id}>{item.name}</option>)}
                        </select>
                    </div>
                </div>
                <div className="flex justify-between">
                    <div></div>
                    <div>
                        <button onClick={(e)=> createUser()} className="m-1 p-2 bg-gray-300">Aprovar</button>
                        <button onClick={(e)=> hideWindow(false)} className="m-1 p-2 bg-gray-300">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default SettingsNewUser;