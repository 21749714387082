import { iSubmissionForm } from "../interface.td";

export class AdminDashboardClass{
    private checkIsValid(response : {"code":string}){
        if(response["code"] === "ADMIN_SESSION_NOT_VALID") document.location = "/admin";
    }
    async submissionStatus(header : string, offset : number){
        const data = await fetch(process.env.REACT_APP_API_ROUTE + "api/admin/submission/list/"+offset,{
            method : "get",
            headers : {
                "Authentication" : header
            }
        });
        const response = await data.text();
        this.checkIsValid(JSON.parse(response));
        return JSON.parse(response) as iSubmissionForm[];
    }

    async submissionTotal(header : string){
        const data = await fetch(process.env.REACT_APP_API_ROUTE + "api/admin/submission/list/count",{
            method : "get",
            headers : {
                "Authentication" : header
            }
        });
        const response = await data.json();
        this.checkIsValid(response);
        return response;
    }

    async getManagerList(header : string, role : string){
        const data = await fetch(process.env.REACT_APP_API_ROUTE + "api/admin/settings/general/" + role,{
            method : "get",
            headers : {
                "Authentication" : header
            }
        });
        const response = await data.json();
        this.checkIsValid(response);
        return response;
    }

    async viewCandidature(id : number, header : string) {
        const data = await fetch(process.env.REACT_APP_API_ROUTE + "api/admin/submission/view/"+id,{
            method : "get",
            headers : {
                "Authentication" : header
            }
        });
        const response = await data.json();
        this.checkIsValid(response);
        return response;
    }

    // i could destructure this piece of crap, but who cares? agenda won't allow me :()
    async approveCandidature(organization_status : string, salary_status : string, startingYear_status : string,
        role_status : string, category_status : string, bi_status : string, submissionDate : string,
        submissionStatus : string, base_salary : string, id : number, status : string, have_debt : string, debt_value : string,
        debt_length : string,header : string) {
        
        const sendState = await fetch(process.env.REACT_APP_API_ROUTE + "api/admin/submission/view/"+id+"/"+status,{
            method : "post",
            headers : {
                "Content-Type" : "application/json",
                "Accept" : "application/json",
                "Authentication" : header
            },
            body : JSON.stringify({
                "organization_status" : organization_status,
                "salary_status" : salary_status,
                "startingYear_status" : startingYear_status,
                "role_status" : role_status,
                "category_status" : category_status,
                "bi_status" : bi_status,
                "submissionDate" : submissionDate,
                "submissionStatus" : submissionStatus,
                "base_salary" : base_salary,
                "have_debt" : have_debt,
                "debt_value" : debt_value,
                "debt_length" : debt_length
            })
        });
        
        const response = await sendState.json();
        this.checkIsValid(response);
        return response;
    }

    async campaignList(header : string) {
        const getlist = await fetch(process.env.REACT_APP_API_ROUTE + "api/admin/settings/campaign/list",{
            headers : {
                "Accept" : "application/json",
                "Authentication" : header
            }
        });
        const response = await getlist.json();
        this.checkIsValid(response);
        return response;
    }

    async createUser(email : string, password : string, phonenumber : number, role : string, name : string, organization : number, header : string){
        const sendData = await fetch(process.env.REACT_APP_API_ROUTE + "api/admin/settings/general/newuser/"+role,{
            method : "post",
            headers : {
                "Accept" : "application/json",
                "Content-Type" : "application/json",
                "Authentication" : header
            },
            body : JSON.stringify({
                "email" : email,
                "password" : password,
                "phone" : phonenumber,
                "role" : role,
                "name" : name,
                "organization" : organization
            })
        });
        const response = await sendData.json();
        this.checkIsValid(response);
        return response;
    }
}