import React, { useState } from 'react';
import MessageScreen from '../Message';
import { AppFooter, AppHeader } from '../../../components/AppHeading';
import { App } from '../../../classes/app';
import { background } from '../../../assets';

function Verify() {
  const [showHideMessage, setShowHideMessage] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');  

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const [code, setCode] = useState(0);

  function showWaitMessage(){
    setTitle("Aguarde");
    setShowHideMessage(true);
  }

  async function sendCode(){
    showWaitMessage();
    setMessage("O código de verificação está sendo enviado...");

    const token = String(document.location.href).split("/")[5];
    const sendcode = await (new App()).verifyAccount(code,token);
    setMessage(sendcode["message"]);

    if(sendcode["code"] === "ACCOUNT_CREATE_CONFIRM_CODE_SUCCESS"){
      if(showHideMessage === false) setRedirectUrl("/");
      setMessage(sendcode["message"]);
    }
  }

  async function sendNewCode(){
    showWaitMessage();
    setMessage("Enviando um novo código...");
    
    const token = String(document.location.href).split("/")[5];
    const sendcode = await (new App()).resendCode(token);
    setMessage(sendcode["message"]);

    if(sendcode["code"] === "ACCOUNT_CREATE_RESEND_CODE_SUCCESS"){
      if(showHideMessage === false) setRedirectUrl("/signup/verify/" + sendcode["token"]);
      setMessage(sendcode["message"]);
    }

  }

  return (
    <body className='flex flex-col h-full'>
      {(showHideMessage ? 
        <MessageScreen 
          title={title}
          message={message}
          redirect={redirectUrl} 
          hideWindow={setShowHideMessage}/> : <></>
      )}
      <AppHeader link='/'/>
      <main className='h-full bg-gray-500 flex items-center justify-start' style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition : "top"
      }}>
        <div className='flex w-[30%] flex-col p-6 m-4 ml-12 bg-white'>
          <h1 className='text-4xl pb-16 self-center place-self-center'>Verificação</h1>
          <input onChange={(e)=> setCode(Number(e.target.value))} className='p-2 border-2 mb-2' type="number" placeholder='Código numerico'/>
          <p onClick={(e)=> sendNewCode()} className='text-sm m-2 text-blue-500 hover:cursor-pointer'>Reenviar código</p>
          <div className='w-full flex flex-col mt-6'>
            <button onClick={(e)=> sendCode()} className='text-sm bg-slate-200 hover:bg-blue-200 mt-2 p-2 flex justify-center content-center'>Verificar</button>
          </div>
        </div>
        <div></div>
      </main>
      <AppFooter/>
    </body>
  );  
}

export default Verify;