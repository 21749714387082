import { useEffect, useState } from "react";
import SettingsNewCampaign from "./components/swc_newCampaign";
import { AdminDashboardClass } from "../../../classes/admin";
import Cookies from "universal-cookie";
interface campaignList{
    id : number, 
    name : string,
    startDate : Date,
    endDate : Date
}

function SwcCampaign(){
    const cookies = new Cookies();
    const [showModal, setShowModal] = useState(false);
    const [campaigns, setCampaigns] = useState<campaignList[]>([]);

    useEffect(()=>{
        async function getCampaigns(){
            const list = await (new AdminDashboardClass()).campaignList(cookies.get("Authentication"));
            setCampaigns(list);
        }
        getCampaigns();
    },[]);

    return (
        <div>
            {(showModal ? <SettingsNewCampaign hideWindow={setShowModal}/> : <></>)}
            <div>
                <div>
                    <h1 className="m-2 font-bold text-lg pb-4">Campanhas</h1>
                </div>
                <div>
                    <ul>
                        {campaigns.map((item,key)=> <li className="flex justify-between m-2 text-sm">
                            <div>{key + 1}</div>
                            <div>{item.name}</div>
                            <div>De {(new Date(item.startDate)).toUTCString()}</div>
                            <div>A {(new Date(item.endDate)).toUTCString()}</div>
                            <div>
                                <button className="bg-blue-300 pl-2 pr-2 p-1">Editar</button>
                                <button className="bg-red-300 pl-2 pr-2 p-1">Apagar</button>
                            </div>
                        </li>)}
                    </ul>
                </div>
                <button onClick={(e)=> setShowModal(true)} className="mt-8 p-2 m-2 bg-blue-300">Adicionar</button>
            </div>
        </div>
    );
}

export default SwcCampaign;