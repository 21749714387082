import { useEffect, useState } from "react";
import CandidateAprove from "./candidateAprove";
import { iOrganization, iSubmissionForm } from "../../interface.td";
import { AdminDashboardClass } from "../../classes/admin";
import { setColor, setText } from "../../functions/colors";
import Cookies from "universal-cookie";
import { App } from "../../classes/app";
import MessageScreen from "../../pages/signup/Message";

function CandidaturesWindow(){
  // animation
    const [loading, setLoading] = useState("solved" || "pending");

    const [showWindow, setShowWindow] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    //
    const cookies = new Cookies();
    const [showCandidate, setShowCandidate] = useState(false);

    const [candidatureData, setCandidatureData]= useState<iSubmissionForm[]>([]);
    const [candidatureDataBackup, setCandidatureDataBackup]= useState<iSubmissionForm[]>([]);

    // filtering
    const [selectOrganization, setSelectOrganization] = useState("all");
    const [selectStatus, setSelectStatus] = useState("all");

    const [targetCandidature, setTargetCandidature] = useState(0);
    const [organization, setOrganization] = useState<iOrganization[]>([]);
    const [offset, setOffset] = useState(1);
    const [pages, setPages] = useState(0);

    // helper for filtering
    const [dataHelper, setDataHelper] = useState("");

    // select for 
    const [selectedPage, setSelectedPage] = useState(0);

    function setCandidatureStatus(data : { submissionStatus : "approved" | "pending" | "rejected" }){
        if("approved" === data.submissionStatus){
            return "approved";
        }
        if("pending" === data.submissionStatus){
            return "pending";
        }
        if("rejected" === data.submissionStatus){
            return "rejected";
        }
        return "rejected";
    }

    async function requestData(){
        const data = await (new AdminDashboardClass()).submissionStatus(cookies.get("Authentication"),offset);
        if(data){
            setCandidatureData(data);
            setCandidatureDataBackup(data);
        }
        const submissionTotal = await (new AdminDashboardClass()).submissionTotal(cookies.get("Authentication"));
        const organizations = await (new App()).getInstitutes();
        if(organizations) setOrganization(organizations);

        setPages(submissionTotal.total);
    }

    useEffect(()=>{
        requestData();
    },[offset])

    useEffect(()=>{
        if(selectOrganization === "all" && selectStatus === "all") setCandidatureData(candidatureDataBackup);
        else{
            if(selectOrganization === "all" && selectStatus !== "all") setCandidatureData((previous)=> candidatureDataBackup.filter((i,key)=> setCandidatureStatus(i) === selectStatus));
            else if(selectOrganization !== "all" && selectStatus === "all") setCandidatureData((previous)=> candidatureDataBackup.filter((i,key)=> i.userProfile.organizationDatabase.organization.name === selectOrganization));
            else if(selectOrganization !== "all" && selectStatus !== "all") setCandidatureData((previous)=> candidatureDataBackup.filter((i,key)=> setCandidatureStatus(i) === selectStatus && i.userProfile.organizationDatabase.organization.name === selectOrganization));
        }
    },[selectOrganization, selectStatus])
    // dear developer that is reading this code, if you know, you know :')
    return(<>
    {(showCandidate ? <CandidateAprove 
                        id={targetCandidature} 
                        hideWindow={setShowCandidate}
                        setRemoteShowWindow={setShowWindow}
                        setRemoteTitle={setTitle}
                        setRemoteMessage={setMessage}
                        setThisShowWindow={setShowCandidate}
                        callback={requestData}
                    /> : <></>)}
        <div className="flex flex-row text-sm mb-4">
        {(showWindow ? <MessageScreen title={title} message={message} hideWindow={setShowWindow}/> : <></>)}
            <div className="flex">
                <p className="mr-4 font-bold">Organização</p>
                <select onChange={(e)=> setSelectOrganization(e.target.value)} name="" id="">
                    <option value="all" selected>Todas</option>
                    {organization.map((element, key)=> <option key={key} value={element.name} >{element.name}</option>)}
                </select>
            </div>
            <div className="ml-8 flex">
                <p className="mr-4 font-bold">Candidaturas</p>
                <select onChange={(e)=> setSelectStatus(e.target.value) } name="" id="">
                    <option value="all" selected>Todas</option>
                    <option value="approved">Aprovadas</option>
                    <option value="rejected">Reprovadas</option>
                    <option value="pending">Pendentes</option>
                </select>
            </div>
        </div>
        <table className="w-full">
            <thead>
                <tr className="text-left text-sm">
                    <th>#</th>
                    <th>Nome</th>
                    <th>Função</th>
                    <th>Organização</th>
                    <th>Data de Submissão</th>
                    <th>Opções</th>
                    <th>Estado</th>
                </tr>
            </thead>
            <tbody>
                {(candidatureData.length) ? candidatureData.map((submission,index)=> 
                    <tr className="text-sm">
                        <td>{submission.id}</td>
                        <td>{submission.userProfile.organizationDatabase.name}</td>
                        <td>{submission.userProfile.organizationDatabase.role.name}</td>
                        <td>{submission.userProfile.organizationDatabase.organization.name}</td>
                        <td>{(submission.createdAt ? 
                            ((new Date(submission.createdAt)).getDate() + "/" + 
                             ((new Date(submission.createdAt)).getMonth() + 1) + "/" + 
                             (new Date(submission.createdAt)).getFullYear()) : 
                            <></>)}</td>
                        <td className="text-center m-1 p-1">
                            {(()=>{
                                return (submission.submissionStatus === "pending") ? <button onClick={(e)=> {
                                    setTargetCandidature(submission.id);
                                    setShowCandidate(true);
                                }} className='bg-green-300 text-center w-full'>Ver</button> : <></>
                            })()}
                            {/* <button className='bg-red-300'>Rejeitar</button> */}
                        </td>
                        <td className={`text-center m-1 p-1 ${setColor(setCandidatureStatus(submission))}`}>{setText(setCandidatureStatus(submission))}</td>
                    </tr>) : <></>}
            </tbody>
        </table>
        <div className="flex text-center flex-wrap items-center pt-4">
                <p className="font-bold">Páginas</p>
                <ul className="flex flex-wrap">
                    {(()=>{
                        const paginations = [];
                        for(let i = 1; i < (pages / 25); i++){
                            paginations.push(
                                <li><button id={String(i)} onClick={(e)=>{
                                    setOffset(i);
                                    setSelectedPage(i);
                                }} className={`p-1 pl-4 pr-4 m-1 ${(selectedPage === i) ? "bg-blue-400" : "bg-blue-200"} text-sm`}>{i+1}</button></li>
                            );
                        }
                        return paginations;
                    })()}
                </ul>
            </div>
    </>)
}

export default CandidaturesWindow;
