import { iSubmissionData, iSubmissionStatus } from "../interface.td";

export class App{
    async submissionStatus(id : number, header : string){
        const data = await fetch(process.env.REACT_APP_API_ROUTE + "api/submission/status",{
            headers : {
                "Authentication" : header
            }
        });
        const response = await data.text();
        return JSON.parse(response) as iSubmissionStatus;
    }

    async submissionAccountStatus(header : string){
        const data = await fetch(process.env.REACT_APP_API_ROUTE + "api/submission/account",{
            headers : {
                "Authentication" : header
            }
        });
        const response = await data.json();
        return response;
    }

    async submissionRequest(identification : string, header : string){
        const data = await fetch(process.env.REACT_APP_API_ROUTE + "api/submission/request",{
            method : "post",
            headers : {
                "Authentication" : header,
                "Content-Type" : "application/json"
            },
            body : JSON.stringify({
                "identification" : identification
            })
        });
        const response = await data.json();
        return response;
    }

    async submissionData(id : number, header : string){
        const data = await fetch(process.env.REACT_APP_API_ROUTE + "api/submission/data",{
            headers : {
                "Authentication" : header
            }
        });
        const response = await data.text();
        return JSON.parse(response) as iSubmissionData;
    }

    async verifyAccount(code : number, token : string) {
        const doRequest = await fetch(process.env.REACT_APP_API_ROUTE + "api/verify/"+token,{
            method : "post",
            headers : {
              "Content-Type" : "application/json",
              "Accept" : "application/json"
            },
            body : JSON.stringify({
              code : code
            })
        });

        const response = await doRequest.json();
        return response;
    }

    async resendCode(token : string) {
        const doRequest = await fetch(process.env.REACT_APP_API_ROUTE + "api/verify/resend/"+token,{
            method : "get",
            headers : {
              "Content-Type" : "application/json",
              "Accept" : "application/json"
            }
        });

        const response = await doRequest.json();
        return response;
    }

    async getInstitutes() {
        const request = await fetch(process.env.REACT_APP_API_ROUTE + "api/institutes",{
            headers : {
              "Accept" : "application/json"
            }
        });
        const data = await request.json();
        return data;
    }

    async getRoles() {
        const request = await fetch(process.env.REACT_APP_API_ROUTE + "api/roles",{
            headers : {
              "Accept" : "application/json"
            }
        });
        const data = await request.json();
        return data;
    }

    async getCategories() {
        const request = await fetch(process.env.REACT_APP_API_ROUTE + "api/categories",{
            headers : {
              "Accept" : "application/json"
            }
        });
        const data = await request.json();
        return data;
    }
}