export class cSignup{
    async Signup(name : string, email : string, password : string, organization : number) {
        const data = await fetch(process.env.REACT_APP_API_ROUTE + "api/signup",{
            method : "post",
            headers : {
                "Accept" : "application/json",
                "Content-Type" : "application/json"
            },
            body : JSON.stringify({
                "name" : name,
                "email" : email,
                "password" : password,
                "institute" : organization
            })
        });
    
        let response = await data.text();
        return JSON.parse(response);
    }

    async Recover(email : string) {
        const data = await fetch(process.env.REACT_APP_API_ROUTE + "api/recover",{
            method : "post",
            headers : {
                "Accept" : "application/json",
                "Content-Type" : "application/json"
            },
            body : JSON.stringify({
                "email" : email
            })
        });
    
        let response = await data.text();
        return JSON.parse(response);
    }

    async RecoverNewPassword(password : string, token : string) {
        const data = await fetch(process.env.REACT_APP_API_ROUTE + "api/recover/" + token,{
            method : "post",
            headers : {
                "Accept" : "application/json",
                "Content-Type" : "application/json"
            },
            body : JSON.stringify({
                "password" : password
            })
        });
    
        let response = await data.text();
        return JSON.parse(response);
    }
}